<template>
  <div class="mailNo">
    <el-card>
      <div slot="header" class="clearfix">
        <el-form :inline="true" ref="form" :model="form" class="demo-form-inline" size="mini">
          <el-form-item label="订单编号：" prop="orderNo">
            <el-input v-model="form.orderNo" clearable></el-input>
          </el-form-item>
          <el-form-item label="快递单号：" prop="mailNo">
            <el-input v-model="form.mailNo" clearable></el-input>
          </el-form-item>
          <el-form-item label="快递公司：" prop="logisticsStatus">
            <el-select v-model="form.cpCode" placeholder="全部" clearable>
              <el-option v-for="(item,i) in cpCodeList" :key="i" :label="item"
                         :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="物流状态：" prop="logisticsStatus">
            <el-select v-model="form.logisticsStatus" placeholder="全部" clearable>
              <el-option v-for="item in logisticsList" :key="item.status" :label="item.statusDesc"
                         :value="item.status"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否订阅：" prop="subscribed">
            <el-select v-model="form.subscribed" placeholder="全部" clearable>
              <el-option v-for="item in subscribedList" :key="item.value" :label="item.label"
                         :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否正常单：" prop="subscribed">
            <el-select v-model="form.status" placeholder="全部" clearable>
              <el-option v-for="item in statusList" :key="item.value" :label="item.label"
                         :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否结单：" prop="subscribed">
            <el-select v-model="form.finished" placeholder="全部" clearable>
              <el-option v-for="item in finishedList" :key="item.value" :label="item.label"
                         :value="item.value"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="打单时间：">
            <el-date-picker
              v-model="form.printDateQuery"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              format='yyyy-MM-dd HH:mm:ss' value-format='yyyy-MM-dd HH:mm:ss'>
            </el-date-picker>
          </el-form-item>
          <el-form-item label="打包时间：">
            <el-date-picker
              v-model="form.packDateQuery"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              format='yyyy-MM-dd HH:mm:ss' value-format='yyyy-MM-dd HH:mm:ss'>
            </el-date-picker>
          </el-form-item>
          <el-form-item label="揽收时间：">
            <el-date-picker
              v-model="form.acceptDateQuery"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              format='yyyy-MM-dd HH:mm:ss' value-format='yyyy-MM-dd HH:mm:ss'>
            </el-date-picker>
          </el-form-item>
          <el-form-item class="btn-right">
            <!--            <el-button @click="onReset">重置</el-button>-->
            <el-button type="primary" @click="onSubmit" :disabled="isLoading">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div>
        <el-table
          :data="tableData"
          border
          v-loading="isLoading"
          style="width: 100%" size="mini"
          :default-sort="{prop: 'printTime', order: 'descending'}">
          <el-table-column type="index" label="编号" width="80"></el-table-column>
          <el-table-column prop="orderNo" label="订单编号"></el-table-column>
          <el-table-column prop="deptSortOut" label="部门归属">
            <template slot-scope="scope">
              <span>{{ scope.row.deptSortOut | deptSortOutFormate }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="platformType" label="订单平台">
            <template slot-scope="scope">
              <span v-if="scope.row.platformType === '1'">快递助手</span>
              <span v-else-if="scope.row.platformType === '2'">我打</span>
              <span v-else-if="scope.row.platformType === '3'">聚水潭</span>
              <span v-else-if="scope.row.platformType === '5'">快麦</span>
              <span v-else></span>
            </template>
          </el-table-column>
          <el-table-column prop="cpCode" label="快递公司编码"></el-table-column>
          <el-table-column prop="mailNo" label="快递单号"></el-table-column>
          <el-table-column prop="shopName" label="店铺名称"></el-table-column>
          <el-table-column prop="sender" label="发件人"></el-table-column>
          <el-table-column prop="senderPhone" label="发件人手机号"></el-table-column>
          <el-table-column prop="mark" label="快递备注"></el-table-column>
          <el-table-column prop="printPhone" label="打单员手机号"></el-table-column>
          <el-table-column prop="printName" label="打单员"></el-table-column>
          <el-table-column prop="packPhone" label="打包员手机号"></el-table-column>
          <el-table-column prop="packName" label="打包员"></el-table-column>
          <el-table-column prop="recipientProvince" label="收件省份"></el-table-column>
          <el-table-column prop="recipientCity" label="收件地级市"></el-table-column>
          <el-table-column prop="recipientArea" label="收件区县"></el-table-column>
          <el-table-column prop="packWeight" label="打包重量"></el-table-column>
          <el-table-column prop="printTime" label="打单时间" sortable>
            <template slot-scope="scope">
              <span>{{ scope.row.printTime | dateFormate }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="packTime" label="打包时间" sortable>
            <template slot-scope="scope">
              <span>{{ scope.row.packTime | dateFormate }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="acceptTime" label="揽收时间" sortable>
            <template slot-scope="scope">
              <span>{{ scope.row.acceptTime | dateFormate }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="logisticsStatusDesc" label="最新物流状态">
            <template slot-scope="scope">
              <el-button size="mini" type="text" @click="handleEdit(scope.row)">更新状态</el-button>
              <span>{{ scope.row.logisticsStatusDesc }}</span>
            </template>
          </el-table-column>
          <el-table-column label="最新物流变更时间">
            <template slot-scope="scope">
              <span>{{ scope.row.theLastTime | dateFormate }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="url" label="物流信息链接">
            <template slot-scope="scope">
              <a :href="scope.row.url" v-if="scope.row.url!==null" target="_blank"
                 style="display:inline-block;text-decoration:none;height:20px;line-height:20px;color:#FFA500;">查看物流信息</a>
              <el-button @click="handleGet(scope.row)" size="mini" type="text" v-else>获取链接</el-button>
            </template>
          </el-table-column>
          <el-table-column label="图片地址">
            <template slot-scope="{row}">
              <el-button @click="getImg(row)" size="mini" type="text" v-if="row.packTime">图片地址</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="运单是否正常">
            <template slot-scope="scope">
              <span v-if="scope.row.status === '0'">正常单</span>
              <span v-else>异常单</span>
            </template>
          </el-table-column>
          <el-table-column prop="finished" label="运单是否结束">
            <template slot-scope="scope">
              <span v-if="scope.row.finished === '0'">未结单</span>
              <span v-else>已结单</span>
            </template>
          </el-table-column>
          <el-table-column prop="subscribed" label="物流是否订阅">
            <template slot-scope="scope">
              <el-button @click="handleSubscribed(scope.row)" size="mini" type="text"
                         v-if="scope.row.subscribed === '0'">手动订阅
              </el-button>
              <span v-else>已订阅</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--      分页组件结构-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.currentPage"
        :page-sizes="[10, 20, 50, 100,200]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="dataTotal"
        :disabled="isLoading"
      >
      </el-pagination>

    </el-card>

  </div>
</template>

<script>
import {findAllMailNoByPage, getLogisticsUrl, updateLogisticsStatus,subscribeLogistics, findCpCodeList} from '@/services/logistics';
import {findAllLogisticsStatus} from '@/services/status';
import {deptSortOutToCn} from "@/utils/enums";
import {getUserInfo} from '@/services/user'
export default {
  name: 'mailNo',
  data() {
    return {
      userInfo: {},
      // 用于存储资源列表数据
      tableData: [],
      form: {
        // 当前显示页号
        currentPage: 1,
        pageSize: 10
      },
      // 数据总数
      dataTotal: 0,
      // 用于存储物流状态列表
      logisticsList: [],
      // deptSortOutList: [],
      subscribedList: [
        {
          value: '0',
          label: '未订阅'
        }, {
          value: '1',
          label: '已订阅'
        }
      ],
      statusList: [
        {
          value: '0',
          label: '正常单'
        }, {
          value: '1',
          label: '异常单'
        }
      ],
      finishedList: [
        {
          value: '0',
          label: '未结单'
        }, {
          value: '1',
          label: '已结单'
        }
      ],
      isLoading: false,
      cpCodeList: [],
    }
  },
  methods: {

    async loadUserInfo() {
      const {data} = await getUserInfo()
      if (data.code === 200) {
        return this.userInfo = data.data
      }
      this.$message.error('获取用户信息失败')
    },
    getImg(row) {
      try {
        let datePath = this.getDatePath(row.packTime)
        let url = 'http://pg.xgkezhan.cn:7001/images/'+datePath+'/'+row.mailNo+'.jpg'
        this.$confirm(url, '图片地址')
      } catch (err) {

      }
    },
    // 每页显示条数变化时触发
    handleSizeChange(val) {
      this.form.pageSize = val
      // 由于修改了每页显示的条数，应当将页数还原默认值1
      this.form.currentPage = 1
      this.loadAllMailNoByPage()
    },
    // 页号改变时触发
    handleCurrentChange(val) {
      this.form.currentPage = val
      this.loadAllMailNoByPage()
    },
    handleEdit(row) {
      this.updateLogistics(row)
    },
    handleGet(row) {
      this.getH5Url(row)
    },

    handleSubscribed(row) {
      this.putSubscribed(row)
    },


    // 添加筛选后的数据查询
    onSubmit() {
      // 请求数据前，将请求的页数更新为1
      this.form.currentPage = 1
      this.loadAllMailNoByPage()
    },
    // 重置按钮点击操作
    onReset() {
      this.$refs.form.resetFields()
    },
    async updateLogistics(row) {
      // 更新物流状态
      const {data} = await updateLogisticsStatus(row)
      if (200 !== data.code) {
        this.$message.error('更新物流状态失败')
      }
      await this.loadAllMailNoByPage()
    },
    async getH5Url(row) {
      // 获取物流信息链接
      const {data} = await getLogisticsUrl(row)
      if (200 !== data.code) {
        this.$message.error('获取物流信息链接失败')
      }
      await this.loadAllMailNoByPage()
    },
    async putSubscribed(row) {
      // 获取物流信息链接
      const {data} = await subscribeLogistics(row)
      if (200 !== data.code) {
        this.$message.error('订阅失败')
      }
      await this.loadAllMailNoByPage()
    },
    async loadLogisticsStatus() {
      // 查询快递状态列表
      const {data} = await findAllLogisticsStatus()
      if (200 === data.code) {
        this.logisticsList = data.data
      } else {
        this.$message.error('查询快递状态列表失败')
      }
    },

    async loadAllMailNoByPage() {
      // 开始加载数据
      this.isLoading = true
      await this.loadUserInfo()
      this.form.deptSortOut = this.userInfo.company
      // 打单时间范围
      if (this.form && this.form.printDateQuery) {
        let printDateBegin = this.form.printDateQuery[0]
        let printDateEnd = this.form.printDateQuery[1]
        this.form.printDateBegin = printDateBegin
        this.form.printDateEnd = printDateEnd
      } else {
        this.form.printDateBegin = ""
        this.form.printDateEnd = ""
      }

      // 打包时间范围
      if (this.form && this.form.packDateQuery) {
        let packDateBegin = this.form.packDateQuery[0]
        let packDateEnd = this.form.packDateQuery[1]
        this.form.packDateBegin = packDateBegin
        this.form.packDateEnd = packDateEnd
      } else {
        this.form.packDateBegin = ""
        this.form.packDateEnd = ""
      }
      // 揽收时间范围
      if (this.form && this.form.acceptDateQuery) {
        let acceptDateBegin = this.form.acceptDateQuery[0]
        let acceptDateEnd = this.form.acceptDateQuery[1]
        this.form.acceptDateBegin = acceptDateBegin
        this.form.acceptDateEnd = acceptDateEnd
      } else {
        this.form.acceptDateBegin = ""
        this.form.acceptDateEnd = ""
      }
      // 分页多条件查询快递单号信息
      const {data} = await findAllMailNoByPage(this.form)
      if (200 === data.code) {
        this.tableData = data.data.records
        this.dataTotal = data.data.total
      }
      this.isLoading = false
    },
    async loadCpCodeList() {
      // 查询快递状态列表
      const {data} = await findCpCodeList()
      if (200 === data.code) {
        this.cpCodeList = data.data
      } else {
        this.$message.error('查询快递编码列表失败')
      }
    },
    // 获取过去几个月的时间
    getPastTime(month) {
      let time = new Date();
      const yy = time.getFullYear(); //获取完整的年份(4位,1970-???)
      const M = time.getMonth() + 1; //获取当前月份(0-11,0代表1月),
      const d = time.getDate(); //获取当前日(1-31)
      const H = time.getHours(); //获取当前小时数(0-23)
      const m = time.getMinutes(); //获取当前分钟数(0-59)
      const s = time.getSeconds(); //获取当前秒数(0-59)
      // 获取指定的过去时间
      const past = M - month;
      const pastM = past < 0 ? past + 12 : past > 10 ? past : "0" + past;
      // 小于9的，在前面加0
      const MM = M > 9 ? M : "0" + M;
      const dd = d > 9 ? d : "0" + d;
      const HH = H > 9 ? H : "0" + H;
      const mm = m > 9 ? m : "0" + m;
      const ss = s > 9 ? s : "0" + s;
      // 指定的过去时间
      const PastTime = yy + "-" + pastM + "-" + dd + " " + HH + ":" + mm + ":" + ss;
      // 当前时间
      const nowTime = yy + "-" + MM + "-" + dd + " " + HH + ":" + mm + ":" + ss;

      return [PastTime, nowTime];
    },

    //  获取时间的年月日
    getDatePath(date) {
      date = new Date(date);
      let month = date.getMonth() + 1
      if (month < 10) {
        month = '0' + month
      }
      let day = date.getDate()
      if (day < 10) {
        day = '0' + day
      }
      return `${date.getFullYear()}-${month}-${day}`
    }

  },
  created() {
    this.loadLogisticsStatus()
    this.loadCpCodeList()
  },
  filters: {
    // 日期过滤器
    dateFormate(date) {
      if (null == date) {
        return ``
      }
      date = new Date(date)
      let month = date.getMonth() + 1
      if (month < 10) {
        month = '0' + month
      }
      let day = date.getDate()
      if (day < 10) {
        day = '0' + day
      }
      let hours = date.getHours()
      if (hours < 10) {
        hours = '0' + hours
      }
      let minutes = date.getMinutes()
      if (minutes < 10) {
        minutes = '0' + minutes
      }
      let seconds = date.getSeconds()
      if (seconds < 10) {
        seconds = '0' + seconds
      }
      return `
       ${date.getFullYear()}-${month}-${day} ${hours}:${minutes}:${seconds}
      `
    },

    deptSortOutFormate(code) {
      return deptSortOutToCn(code)
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
